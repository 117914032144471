export default class User {
  constructor(username, email, password, name) {
    this.name = name;
    this.first_name = null;
    this.last_name = null;
    this.username = username;
    this.email = email;
    this.password = password;
    this.roles = []
  }
}
