<script>
const routes = [

  { path: '/', redirect: 'home' },
  { path: '/home', name: 'home', component: () => import('../pages/Dashboard-v2.vue') },
  { path: '/provinces', name: 'provinces', component: () => import('../components/share/ReportProvince') },
  { path: '/areas', name: 'areas', component: () => import('../components/share/ReportArea') },
  { path: '/council/area', name: 'cilarea', component: () => import('../components/council/CouncilArea') },
  { path: '/council/sub_district', name: 'cilsubdis', component: () => import('../components/council/CouncilSubDistrict') },
  { path: '/council/unit', name: 'cilunit', component: () => import('../components/council/CouncilUnit') },
  { path: '/council/district', name: 'cildis', component: () => import('../components/council/CouncilDistrict') },
  { path: '/president/area', name: 'prearea', component: () => import('../components/president/PresidentArea') },
  { path: '/president/sub_district', name: 'presubdis', component: () => import('../components/president/PresidentSubDistrict') },
  { path: '/president/district', name: 'predis', component: () => import('../components/president/PresidentDistrict') },
  { path: '/president/unit', name: 'preunit', component: () => import('../components/president/PresidentUnit') },
  { path: '/login', name: 'login', component: () => import('../pages/User-login-v3.vue') },
  { path: '/logout', name: 'logout', component: () => import('../components/share/LogOut') },




  // { path: '/', redirect: 'home' },
  // { path: '/home', name: 'home', component: () => import('../pages/Home') },
  // { path: '/login', name: 'login', component: () => import('../pages/User-login-v3') },

  // { path: '/devices', name: 'devices', component: () => import('../pages/Devices') },
  // { path: '/devices/:host_id', name: 'device_edit', component: () => import('../pages/DeviceEdit') },
  // { path: '/tree', name: 'tree', component: () => import('../pages/NetworkTree') },
  // { path: '/map', name: 'map', component: () => import('../pages/NetworkMap') },
  // { path: '/buildingsmap', name: 'building_map', component: () => import('../pages/BuildingsMap') },
  // { path: '/com-lab', name: 'computer-lab', component: () => import('../pages/ComputerLab') },
  // { path: '/speedtest', name: 'speedtest', component: () => import('../pages/Speedtest') },
  // // **********************************************************************************************
  // { path: '/servers/dashboard', name: 'servers-dashboard', component: () => import('../pages/servers/Dashboard.vue') },
  // { path: '/servers/location', name: 'servers-location', component: () => import('../pages/servers/Location.vue') },
  // // **********************************************************************************************
  // { path: '/switchs/dashboard', name: 'switchs-dashboard', component: () => import('../pages/switchs/Dashboard.vue') },
  // { path: '/switchs/location', name: 'switchs-location', component: () => import('../pages/switchs/Location.vue') },
  // { path: '/switchs/monitor', name: 'switchs-monitor', component: () => import('../pages/switchs/Monitor.vue') },
  // { path: '/switchs/summary', name: 'switchs-summary', component: () => import('../pages/switchs/Summary.vue') },
  // { path: '/switchs/portsmonitor', name: 'switchs-ports', component: () => import('../pages/switchs/PortsMonitor.vue') },
  // { path: '/switchs/portsmonitor/:host_id', name: 'switchs-ports-host', component: () => import('../pages/switchs/PortsMonitor.vue') },
  // { path: '/switchs/vlansmonitor', name: 'switchs-vlans', component: () => import('../pages/switchs/VlansMonitor.vue') },
  // // **********************************************************************************************
  // { path: '/wifis/dashboard', name: 'wifis-dashboard', component: () => import('../pages/wifis/Dashboard.vue') },
  // { path: '/wifis/location', name: 'wifis-location', component: () => import('../pages/wifis/Location.vue') },
  // { path: '/wifis/monitor', name: 'wifis-monitor', component: () => import('../pages/wifis/Monitor.vue') },
  // { path: '/wifis/survey', name: 'wifis-survey', component: () => import('../pages/wifis/Survey.vue') },
  // { path: '/wifis/survey/:building_id', name: 'wifis-survey-building', component: () => import('../pages/wifis/SurveyBuilding.vue') },
  // // **********************************************************************************************
  // { path: '/ipphones/dashboard', name: 'ipphones-dashboard', component: () => import('../pages/ipphones/Dashboard.vue') },
  // { path: '/ipphones/location', name: 'ipphones-location', component: () => import('../pages/ipphones/Location.vue') },
  // // **********************************************************************************************
  // { path: '/erp/orgunit', name: 'erp-orgunit', component: () => import('../pages/erp/OrgUint.vue') },
  // { path: '/erp/ed/documents', name: 'erp-ed-documents', component: () => import('../pages/erp/ed/Documents.vue') },
  // { path: '/erp/hr/staffs', name: 'erp-hr-staffs', component: () => import('../pages/erp/hr/Staffs.vue') },
  // // **********************************************************************************************
  // { path: '/system/users', name: 'sys-users', component: () => import('../pages/system/Users.vue') },
  // { path: '/system/groups', name: 'sys-groups', component: () => import('../pages/system/Groups.vue') },
]

export default routes
</script>
