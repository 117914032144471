<template>
  <div class="menu">
    <div class="menu-profile">
      <div class="menu-profile-link">
        <div class="menu-profile-cover with-shadow"></div>
        <div class="menu-profile-image menu-profile-image-icon bg-gray-900 text-gray-600">
          <i class="fa fa-user"></i>
        </div>
        <div class="menu-profile-info">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">{{ currentUser ? currentUser.name : 'Guest' }}</div>
          </div>
          <small>{{ currentUser ? user_groups[currentUser.group_id] : '...'}}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
  name: 'SidebarNavProfile',
  data() {
    return {
      stat: '',
      appOptions: AppOptions,
      user_groups: [
        'ว่าง',
        'ผู้ดูแลระบบ',
        'คณะกรรมการกลาง',
        'คณะกรรมการประจำเขต'
      ],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    expand: function () {
      this.stat = this.stat == 'expand' ? 'collapse' : 'expand'
    },
  },
}
</script>
