<template>
  <div>
    <!-- BEGIN #header -->
    <div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
      <!-- BEGIN navbar-header -->
      <div class="navbar-header">
        <button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarEndMobile" v-if="appOptions.appSidebarTwo">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/" class="navbar-brand"><span class="navbar-logo"></span> <b>Surin</b>&nbsp;Election.</router-link>
        <button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && !appOptions.appSidebarNone">
          <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && appOptions.appSidebarNone">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button type="button" class="navbar-mobile-toggler" v-on:click="toggleHeaderMegaMenuMobile" v-if="appOptions.appHeaderMegaMenu">
          <span class="fa-stack fa-lg text-inverse mt-2">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarMobile" v-if="!appOptions.appSidebarNone">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- END navbar-header -->

      <!-- BEGIN header-nav -->
      <div class="navbar-nav">
        <b-nav-item-dropdown right menu-class="me-1" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center" no-caret>
          <template slot="button-content">
            <div class="image image-icon bg-gray-800 text-gray-600">
              <i class="fa fa-user"></i>
            </div>
            <span class="d-none d-md-inline">{{ currentUser ? currentUser.displayname : '' }}</span> <b class="caret"></b>
          </template>
          <b-dropdown-item v-if="loggedIn" href @click.prevent="logOut">Log Out</b-dropdown-item>
          <router-link v-else :to="{ path: '/login' }" class="dropdown-item">Log In</router-link>
        </b-nav-item-dropdown>
        <div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
        <div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
          <a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
            <i class="fa fa-th"></i>
          </a>
        </div>
      </div>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
  name: 'Header',
  data() {
    return {
      appOptions: AppOptions,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push({ path: '/login' })
    },
    toggleSidebarMobile() {
      this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled
    },
    toggleSidebarEnd() {
      this.appOptions.appSidebarEndToggled = !this.appOptions.appSidebarEndToggled
    },
    toggleSidebarEndMobile() {
      this.appOptions.appSidebarEndMobileToggled = !this.appOptions.appSidebarEndMobileToggled
    },
    toggleTopMenuMobile() {
      this.appOptions.appTopMenuMobileToggled = !this.appOptions.appTopMenuMobileToggled
    },
    toggleHeaderMegaMenuMobile() {
      this.appOptions.appHeaderMegaMenuMobileToggled = !this.appOptions.appHeaderMegaMenuMobileToggled
    },
    checkForm: function (e) {
      e.preventDefault()
      this.$router.push({ path: '/extra/search' })
    },
  },
}
</script>
