// import axios from 'axios';
import axios from '../plugins/axios';

class AuthService {
  login(user) {
    return axios
      .post('auth/login', {
        username: user.username,
        password: user.password
      })
      .then((res) => {
        console.log('1.1')
        if (res) {
          console.log('1.2')
          localStorage.setItem('user', JSON.stringify(res.data));
        }
        console.log('1.3')
        return res.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios
      .post('auth/register', {
        name: user.first_name + ' ' + user.last_name,
        email: user.email,
        password: user.password,
        c_password: user.password
      })
      .then(user => {
        if (user.accessToken) {
          localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
      });
  }
}

export default new AuthService();