import AuthService from '../_services/auth.service';
import User from "../_models/user";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {
  status: {
    loggedIn: true
  },
  user: user.user
} : {
  status: {
    loggedIn: false
  },
  user: new User("Guest", "guest@srru.ac.th", "", "Guest")
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({
      commit
    }, user) {
      return AuthService.login(user).then(
        user => {
          // console.log(user)
          console.log('2.1')
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          // console.log(error)
          console.log('2.2')
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({
      commit
    }) {
      AuthService.logout();
      commit('logout');
    },
    register({
      commit
    }, user) {
      return AuthService.register(user).then(
        user => {
          commit('registerSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user.user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user.user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};